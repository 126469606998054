import { UseMutationOptions } from 'react-query';
import {
  ApiRequest,
  DataDocument,
  ErrorDocument,
  useApi,
  useCreate,
} from '@brainstud/academy-api';

export interface IVerifyEmailSignatureProps {
  account: string;
  signature: string;
  expires_at: string;
}

type Settings = UseMutationOptions<
  DataDocument<undefined>,
  ErrorDocument,
  IVerifyEmailSignatureProps
>;

export function useVerifyEmailSignature(settings?: Settings) {
  const context = useApi();
  const {
    parameters: { account, signature, expires_at },
  } = context;
  const request = new ApiRequest(
    {
      baseName: 'v1.verifyEmailAddress',
      baseUri: '/v1/account_actions/verify_email_address',
      uri: `/${account}/${signature}/${expires_at}`,
    },
    context
  );
  return useCreate<undefined, IVerifyEmailSignatureProps>(request, settings);
}
