import { useCallback, useEffect, useState } from 'react';
import {
  IVerifyEmailSignatureProps,
  useVerifyEmailSignature,
} from '@brainstud/academy-api/Hooks/useVerifyEmailSignature';
import LoginView from '@brainstud/account-panel/Views/Unauthenticated/LoginView/LoginView';
import { AuthLayout } from 'Layouts/AuthLayout/AuthLayout';
import { useRouter } from 'next/router';
import { toLayout } from '../../../Utils';

export default function LoginPage() {
  const { query } = useRouter();
  const verifyEmailSignature = useVerifyEmailSignature();
  const [verifyStatus, setVerifyStatus] = useState<number | null>(null);

  const handleVerification = useCallback(
    async (verifyParams: IVerifyEmailSignatureProps) => {
      const res = await verifyEmailSignature.mutateAsync(verifyParams);
      setVerifyStatus(res.statusCode);
    },
    [verifyEmailSignature]
  );

  useEffect(() => {
    const verifyParams = {
      account: query.account as string,
      signature: query.signature as string,
      expires_at: query.expires_at as string,
    };

    if (Object.values(verifyParams).every((param) => !!param)) {
      handleVerification(verifyParams);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [query]);

  return (
    <LoginView
      verifyStatus={verifyStatus}
      isLoading={verifyEmailSignature.isLoading}
    />
  );
}

LoginPage.getLayout = toLayout(AuthLayout);
