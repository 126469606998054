import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useApi, useMe } from '@brainstud/academy-api';
import { Button, Callout, Input } from '@brainstud/ui';
import { Form } from '@brainstud/universal-components';
import { ChevronRight } from '@mui/icons-material';
import axios, { AxiosRequestHeaders } from 'axios';
import classNames from 'classnames/bind';
import { Link } from 'Components';
import { useReturnToValue } from 'Hooks';
import { useRouter } from 'next/router';
import { useToaster } from 'Providers';
import { useTranslator } from 'Providers/Translator';
import { sanitizer } from 'Utils/Sanitizer';
import { SubHeader } from '../../../Layouts';
import styles from './LoginView.module.css';

const cx = classNames.bind(styles);

type Props = {
  verifyStatus: number | null;
  isLoading?: boolean;
};

type THandleLogin = (value: {
  email_address: string;
  password: string;
}) => void;

const LoginView = ({ verifyStatus, isLoading }: Props) => {
  const [me] = useMe();
  const [t] = useTranslator();
  const [returnTo, clearReturnTo] = useReturnToValue();
  const [setFlash] = useToaster();
  const queryClient = useQueryClient();
  const router = useRouter();
  const { headers: apiHeaders } = useApi();

  const [notVerified, setNotVerified] = useState(false);
  const [email, setEmail] = useState<string>('');

  const verifyStatusMessage = useMemo(() => {
    switch (verifyStatus) {
      case 202:
        return t('verify.success');
      case 422:
      case 404:
        return t('verify.error');
      default:
        return '';
    }
  }, [t, verifyStatus]);

  useEffect(() => {
    if (verifyStatus === 202) {
      setFlash(verifyStatusMessage, 'success');
    }
  }, [setFlash, verifyStatus, verifyStatusMessage]);

  const verifyUrl = useMemo(() => {
    const link = '/auth/verify-email';
    if (email) {
      return `${link}?email=${email}`;
    }
    return link;
  }, [email]);

  const getFeedbackMessage = useCallback(
    (statusCode: number) =>
      ({
        [statusCode as 200 | 201 | 204]: t('login.messages.success'),
        401: t('login.messages.failed'),
        422: t('login.messages.failed'),
        500: t('login.messages.error'),
      })[statusCode as 401 | 422 | 500] || t('login.messages.error'),
    [t]
  );

  const handleFormSubmit = useCallback<THandleLogin>(
    async (values) => {
      setEmail(values.email_address);
      const response = await axios
        .post(
          '/auth/login',
          {
            email_address: values.email_address,
            password: values.password,
          },
          { headers: apiHeaders as AxiosRequestHeaders }
        )
        .catch((error) => {
          if (error.response?.status === 403) {
            setNotVerified(true);
          } else {
            setFlash(getFeedbackMessage(error.response!.status), 'error');
          }
        });
      if (response?.status) {
        if ([200, 201, 204].includes(response.status)) {
          queryClient.invalidateQueries();
          clearReturnTo();
          const { tokenType, accountId, accessToken } = response.data;
          switch (tokenType) {
            case 'Setup': {
              router.push(
                `/auth/two-factor/${accountId}/setup?returnTo=${returnTo}&token=${accessToken}`
              );
              break;
            }
            case 'Login': {
              router.push(
                `/auth/two-factor/${accountId}/login?returnTo=${returnTo}&token=${accessToken}`
              );
              break;
            }
            default: {
              setFlash(getFeedbackMessage(response.status), 'success');
              router.push(returnTo);
            }
          }
        } else if (response) {
          setFlash(getFeedbackMessage(response.status), 'error');
        }
      }
    },
    [
      apiHeaders,
      setFlash,
      getFeedbackMessage,
      queryClient,
      clearReturnTo,
      returnTo,
      router,
    ]
  );

  return (
    <div className={cx(styles.base)}>
      <SubHeader>
        <h1
          dangerouslySetInnerHTML={{
            __html: sanitizer(t('login.title', { name: me?.portal?.().name })),
          }}
        />
        <p>{t('login.subtitle')}</p>
      </SubHeader>
      <Form
        onSubmit={handleFormSubmit}
        autoReset={false}
        disableAfterSubmit={false}
      >
        <Input
          type="email"
          label={t('login.email')}
          name="email_address"
          rules="required"
        />
        <Input
          type="password"
          label={t('login.password')}
          name="password"
          rules="required"
        />
        <p>
          <Link href="/auth/login/register">{t('login.register')}</Link>{' '}
          {t('or')}{' '}
          <Link href="/auth/login/forgot-password">
            {t('login.forgot_password')}
          </Link>
        </p>
        <div style={{ marginTop: '2rem' }}>
          <Button type="submit" style={{ marginRight: '1rem' }}>
            <span>{t('login.action')}</span>
            <ChevronRight fontSize="small" />
          </Button>
          <Button type="reset" quiet to="/auth">
            {t('go_back')}
          </Button>
        </div>
      </Form>
      <p className={styles['last-message']}>
        {`${t('login.no_account')} `}
        <Link href="/auth/login/register">
          {t('login.no_account_register')}
        </Link>
      </p>
      {verifyStatus && verifyStatus !== 202 && !isLoading && (
        <Callout error className={cx(styles.verifyError)}>
          {verifyStatusMessage}
          <Link href={verifyUrl}>{t('click_here')}</Link>
        </Callout>
      )}
      {notVerified && (
        <Callout error>
          {t('login.messages.not_verified')}
          <Link href={verifyUrl}>{t('click_here')}</Link>
        </Callout>
      )}
    </div>
  );
};

export default LoginView;
